import { IconName } from "@asayinc/component-library";
import {
  Communication,
  CorporateActionCommunication,
  ProxyCommunication,
} from "src/types";
import { IssuerType } from "src/types/Communication";

export function getProposals(
  communication: Communication
): { icon: IconName; proposal: string }[] {
  const { security, type } = communication;
  const { issuerName, issuerType } = security;

  if (type === "corporate_action_election") {
    const { corporateActionEvent } =
      communication as CorporateActionCommunication;
    const { type } = corporateActionEvent;
    switch (type) {
      case "consent_solicitation":
        return [
          {
            icon: "VcaConsentSolicitationOutline",
            proposal: `${issuerName} is requesting your consent as a shareholder to make a change to its stock agreement.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "dividend_option":
        return [
          {
            icon: "VcaDividendOptionOutline",
            proposal: `${issuerName} is letting their shareholders like you choose whether you want to receive your dividend in cash or stock.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "dividend_reinvestment_plan":
        return [
          {
            icon: "VcaDividendReinvestmentOutline",
            proposal: `Dividend Reinvestment Plans allow shareholders like you to automatically re-invest your dividends to get more shares of the company or fund based on the current market rate.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "exchange_offer":
        return [
          {
            icon: "VcaExchangeOfferOutline",
            proposal: `${issuerName} is granting the opportunity for their shareholders to exchange their current shares for those of another entity.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "merger_consideration_election":
        return [
          {
            icon: "VcaMergerOutline",
            proposal: `${issuerName} is merging with another entity and is giving their shareholders the opportunity to specify how their shares should be converted or carried through the merger.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "odd_lot_offer":
        return [
          {
            icon: "VcaOddLotOfferOutline",
            proposal: `Companies or funds may announce an Odd Lot Offer in effort to standardize their capitalization table.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "buyback":
        return [
          {
            icon: "VcaBuybackOutline",
            proposal: `${issuerName} is offering to purchase your shares back from you in exchange for the cash value of the shares.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "buyback_auction":
        return [
          {
            icon: "VcaAuctionOutline",
            proposal: `This buyback is conducted via dutch auction. This means the final purchase price of your shares will be determined after the offer closes.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "offer_to_purchase":
        return [
          {
            icon: "VcaOfferToPurchaseOutline",
            proposal: `Offers to Purchase occur when a third party, usually another private or public company, makes an offer to purchase outstanding shares of another company.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "offer_to_purchase_auction":
        return [
          {
            icon: "VcaAuctionOutline",
            proposal: `This tender offer is conducted via dutch auction. This means the final purchase price of your shares will be determined after the offer closes.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "preferential_offer":
        return [
          {
            icon: "IssuerOutline",
            proposal: `Unsupported corporate action type.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "rights_issue":
        return [
          {
            icon: "VcaRightsIssueOutline",
            proposal: `Companies or funds offer Rights Issues when they want to let their current shareholders participate in a future event where the company may be issuing new stock.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "rights_offer":
        return [
          {
            icon: "VcaRightsOfferOutline",
            proposal: `Companies or funds conduct Rights Offers when they want to let shareholders participate in a future event where you can exchange your rights for a new instrument.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "rights_offer_oversubscription":
        return [
          {
            icon: "VcaRightsOversubscriptionOutline",
            proposal: `This Rights Offer allows oversubscription, which means you can opt in to receive more shares at the discounted rate.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      case "informational":
        return [
          {
            icon: "VcaInformationalOutline",
            proposal: `Certain corporate actions have a default option, meaning that shareholders who don't submit an election will be defaulted to an election determined by the issuer.`,
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
      default:
        return [
          {
            icon: "IssuerOutline",
            proposal: "Unknown corporate action type",
          },
          {
            icon: "InfoOutline",
            proposal: `This notice is for informational purposes only and does not
            constitute a recommendation of any security, investment
            strategy, or transaction.`,
          },
        ];
    }
  }

  if (type === "consent_solicitation") {
    return [
      {
        icon: "ConsentSolicitationDefaultOutline",
        proposal:
          "Consent solicitations are a way for companies and funds to enact proposed changes to their organization without holding a shareholder meeting.",
      },
      {
        icon: "ProposalGeneralOutline",
        proposal:
          "The important matters of consent solicitations range from standard proposals you may see at annual general meetings, such as approving a new director for the board, to less common proposals like mergers or acquisitions.",
      },
    ];
  }

  const { proxyEvent, proposals } = communication as ProxyCommunication;
  const { meeting, type: proxyType } = proxyEvent;

  // NOTE: The order of the conditionals below is intentional.
  if (
    proxyType === "contested_annual_meeting" ||
    proxyType === "contested_special_meeting"
  ) {
    return [
      {
        icon: "ConsentSolicitationDefaultOutline",
        proposal: `${meeting?.contestingParty} is leveraging the shares they own of ${issuerName} in an attempt to influence the company’s direction.`,
      },
      {
        icon: "AnnualMeetingDirectorsOutline",
        proposal: `On the next page, you’ll be able to vote across nominees proposed by management and nominees proposed by ${meeting?.contestingParty} to determine who will serve on the ${issuerName} Board of Directors.`,
      },
    ];
  } else if (issuerType === IssuerType.INVESTMENT_TRUST) {
    return [
      {
        icon: "AnnualMeetingDirectorsOutline",
        proposal:
          "The important matters voted on at special meetings may include proposals related to the election of the fund's trustees or a reorganization of how the fund is structured.",
      },
      {
        icon: "VcaAuctionOutline",
        proposal:
          "Some types of funds are not required to hold annual meetings, but will hold a special meeting when needed. As a result, the opportunity to vote proxy with your shares of a fund may only happen once every few years.",
      },
    ];
  } else {
    // This is for annual_meeting and special_meeting

    const shareholderProposals = proposals.filter(
      (proposal) => proposal.type === "shareholder_proposal"
    );

    const proposalDescriptions = proposals.reduce((acc, proposal) => {
      const { type } = proposal;

      // We don't want to include the default proposal if it already exists
      if (
        type !== "shareholder_proposal" &&
        type !== "board_of_directors_nomination" &&
        type !== "executive_compensation" &&
        type !== "ratification_of_auditors" &&
        acc["default"]
      ) {
        return acc;
      }

      if (!acc[type]) {
        switch (type) {
          case "board_of_directors_nomination":
            acc[type] = {
              icon: "AnnualMeetingDirectorsOutline",
              proposal:
                "A group of individuals has been nominated to represent shareholders and oversee, advise, and govern the company. Your vote helps determine if the proposed directors should be approved.",
            };
            return acc;
          case "executive_compensation":
            acc[type] = {
              icon: "AnnualMeetingCompensationOutline",
              proposal:
                "Compensation packages for the company's executives are up for approval. You can vote on whether their compensation should be approved.",
            };
            return acc;
          case "ratification_of_auditors":
            acc[type] = {
              icon: "AnnualMeetingAuditorsOutline",
              proposal:
                "The company has nominated a firm to audit their financial statements, and this is your opportunity to vote on whether this firm should be approved.",
            };
            return acc;
          case "shareholder_proposal":
            acc[type] = {
              icon: "ProposalGeneralOutline",
              proposal: `This ballot contains ${
                shareholderProposals.length
              } shareholder proposal${
                shareholderProposals.length > 1 ? "s" : ""
              }. These proposals are submitted by shareholders instead of management and may reference matters including gender and racial pay equality, carbon footprint, shareholder representation on the company board, and more.`,
            };
            return acc;
          default:
            acc["default"] = {
              icon: "ProposalGeneralOutline",
              proposal:
                "Most public companies hold meetings once a year in order to allow shareholders like you to influence corporate governance.",
            };
            return acc;
        }
      }
      return acc;
    }, {});

    const proposalTypes = Object.keys(proposalDescriptions);
    const orderedDescriptions = [];

    if (proxyType === "special_meeting") {
      orderedDescriptions.push({
        icon: "SpecialMeetingDefaultOutline",
        proposal:
          "Special meetings are called by a company's Board of Directors or investors when an important matter comes up that cannot wait to be voted on at the company's annual meeting.",
      });
    }

    if (proposalTypes.includes("shareholder_proposal")) {
      orderedDescriptions.push(proposalDescriptions["shareholder_proposal"]);
    }

    if (proposalTypes.includes("board_of_directors_nomination")) {
      orderedDescriptions.push(
        proposalDescriptions["board_of_directors_nomination"]
      );
    }

    if (proposalTypes.includes("ratification_of_auditors")) {
      orderedDescriptions.push(
        proposalDescriptions["ratification_of_auditors"]
      );
    }

    if (proposalTypes.includes("executive_compensation")) {
      orderedDescriptions.push(proposalDescriptions["executive_compensation"]);
    }

    if (proposalTypes.includes("default")) {
      orderedDescriptions.push(proposalDescriptions["default"]);
    }

    // Default one to add if we don't have two blurbs
    orderedDescriptions.push({
      icon: "SpecialMeetingDefaultOutline",
      proposal: `Proxy voting regulations are governed by a combination of incorporation laws and stock exchange rules.`,
    });

    // Note: We should always expect to have at least two blurbs
    return orderedDescriptions.slice(0, 2);
  }
}
