import dayjs from "dayjs";
import { Communication, ProxyCommunication } from "src/types";
import { Format } from "src/types/Communication";

export function getLatestSubmitDate(communication: Communication) {
  const { voteResults } = communication as ProxyCommunication;
  const sortedVoteResults = [...voteResults].sort((a, b) => {
    return new Date(b.created).getTime() - new Date(a.created).getTime();
  });
  return sortedVoteResults.length > 0
    ? dayjs(sortedVoteResults[0].created).format("YYYY-MM-DD")
    : null;
}

export function getVoteCardDescription(
  communication: Communication,
  selectedFormat: Format
) {
  if (selectedFormat.canVote === "false") {
    return "Shareholders can only vote online for this meeting.";
  }

  const lastSubmittedDate = getLatestSubmitDate(communication);
  if (lastSubmittedDate && selectedFormat.canVote === "true") {
    return "Choose whether you would like to vote at the meeting or submit your online ballot now. If you choose to vote at the meeting, the vote you have already submitted will be canceled, and your previous vote choices will not be available when you view your vote history.";
  }

  if (!lastSubmittedDate && selectedFormat.canVote === "false") {
    return "You will be directed to your online ballot after submitting this form.";
  }

  if (!lastSubmittedDate && selectedFormat.canVote === "true") {
    return "Choose whether you would like to vote at the meeting or continue to vote online beforehand.";
  }

  return "";
}

export function getMeetingAttendanceTypeOptions(
  communication: Communication
): { label: string; value: string; id: string }[] {
  const {
    proxyEvent: {
      meeting: { formats },
    },
  } = communication as ProxyCommunication;

  const MEETING_ATTENDANCE_OPTIONS = {
    in_person: {
      id: "attendance-in-person",
      value: "in_person",
      label: "Attend in-person",
    },
    virtual: {
      id: "attendance-online",
      value: "virtual",
      label: "Attend online",
    },
    telephone: {
      id: "attendance-telephone",
      value: "telephone",
      label: "Attend via telephone",
    },
  };

  return formats.map((format) => MEETING_ATTENDANCE_OPTIONS[format.format]);
}

export const getElectedToVoteInPersonOptions = (
  selectedFormat: Format | undefined
) => {
  if (selectedFormat?.canVote === "false") {
    return [
      {
        label: "Vote online beforehand",
        value: "false",
      },
    ];
  }

  const options = [
    {
      label: "Vote online beforehand",
      value: "false",
    },
    {
      label: "Vote at the meeting",
      value: "true",
    },
  ];
  return options;
};
