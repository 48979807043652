import {
  Communication,
  CorporateActionCommunication,
  ProxyCommunication,
} from "src/types";
import { CorporateActionEventType } from "src/types/Communication";
import { formatDate } from "src/utils/formatDate";

export function getTitle(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const {
      corporateActionEvent: { type: corporateActionType },
    } = communication as CorporateActionCommunication;

    return CORPORATE_ACTION_EVENT_TYPE_LABEL[corporateActionType];
  }

  const {
    proxyEvent: { type: proxyType, meeting },
  } = communication as ProxyCommunication;
  switch (type) {
    case "proxy_vote":
      if (
        proxyType === "annual_meeting" ||
        proxyType === "contested_annual_meeting"
      ) {
        return `${
          meeting
            ? formatDate({
                date: meeting.meetingDate,
                format: "YYYY",
              })
            : ""
        } Annual Meeting`;
      } else {
        return "Special Meeting";
      }
    case "consent_solicitation":
      return "Consent Solicitation";
    case "proxy_informational":
      return "Proxy Informational";
    default:
      return "-";
  }
}

export function getSubmitByDate(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const {
      corporateActionEvent: { electionCutoff },
    } = communication as CorporateActionCommunication;
    return formatDate({ date: electionCutoff });
  }

  const {
    proxyEvent: { voteCutoff },
  } = communication as ProxyCommunication;
  return formatDate({ date: voteCutoff });
}

export function getSubmitByTime(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const {
      corporateActionEvent: { electionCutoff },
    } = communication as CorporateActionCommunication;
    return formatDate({ date: electionCutoff, format: "HH:mm" });
  }

  const {
    proxyEvent: { voteCutoff },
  } = communication as ProxyCommunication;
  return formatDate({ date: voteCutoff, format: "HH:mm" });
}

export function isPassedCutoffDate(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const {
      corporateActionEvent: { electionCutoff },
    } = communication as CorporateActionCommunication;
    return new Date().getTime() > new Date(electionCutoff).getTime();
  }

  const {
    proxyEvent: { voteCutoff },
  } = communication as ProxyCommunication;
  return new Date().getTime() > new Date(voteCutoff).getTime();
}

export function hasVotedAlready(communication: Communication) {
  const { type } = communication;
  if (type === "corporate_action_election") {
    const { electionResults } = communication as CorporateActionCommunication;
    return electionResults.length > 0;
  }
  const { voteResults } = communication as ProxyCommunication;
  return voteResults.length > 0;
}

export function getButtonType(communication: Communication) {
  const { type } = communication;
  if (isPassedCutoffDate(communication)) {
    return "secondary";
  }

  if (type === "corporate_action_election") {
    const { electionResults } = communication as CorporateActionCommunication;
    if (electionResults.length > 0) {
      return "secondary";
    }
    return "primary";
  }

  const { voteResults } = communication as ProxyCommunication;
  if (voteResults.length > 0) {
    return "secondary";
  }

  return "primary";
}

export function getCtaText(communication: Communication) {
  const { type, meetingAttendance } = communication;
  if (isPassedCutoffDate(communication)) {
    return "Check results";
  }

  if (meetingAttendance?.legalProxy) {
    return "View";
  }

  if (type === "corporate_action_election") {
    const { electionResults } = communication as CorporateActionCommunication;
    if (electionResults.length > 0) {
      return "Update";
    }
    return "Elect";
  }

  const { voteResults } = communication as ProxyCommunication;
  if (voteResults.length > 0) {
    return "Update";
  }

  return "Vote";
}

export function getAccountNumbers(communcation: Communication) {
  const { positions } = communcation;
  const accountNumbers = positions.map(
    (position) => position.accountIdentifier
  );
  return accountNumbers;
}

export function getUniqueAccountNumbersFromCommunications(
  communications: Communication[]
) {
  const accountNumbers = communications.reduce(
    (acc: string[], communication: Communication) => {
      const accountNumbers = getAccountNumbers(communication);
      return [...acc, ...accountNumbers];
    },
    []
  );
  return [...new Set(accountNumbers)];
}

export const CORPORATE_ACTION_EVENT_TYPE_LABEL: {
  [key in CorporateActionEventType]: string;
} = {
  consent_solicitation: "Consent Solicitation",
  dividend_option: "Dividend Option",
  dividend_reinvestment_plan: "Dividend Reinvestment Plan",
  exchange_offer: "Exchange Offer",
  merger_consideration_election: "Merger with Election",
  odd_lot_offer: "Odd Lot Offer",
  buyback: "Buyback",
  buyback_auction: "Buyback",
  offer_to_purchase: "Offer to Purchase",
  offer_to_purchase_auction: "Offer to Purchase",
  preferential_offer: "Preferential Offer",
  rights_issue: "Rights Issue",
  rights_offer: "Rights Offer",
  rights_offer_oversubscription: "Rights Offer",
  informational: "Informational",
};
