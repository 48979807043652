import { Text } from "@asayinc/component-library";
import { Box, Stack, useTheme } from "@mui/material";

export function CatchAll() {
  const theme = useTheme();
  return (
    <Stack sx={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
      <Box>
        <img
          src="https://broker-assets.saytechnologies.com/images/shared-site-assets/svgs/404.svg"
          alt="404"
        />
      </Box>
      <Stack
        sx={{
          mt: 8,
          [theme.breakpoints.down("sm")]: {
            textAlign: "center",
            mx: 6,
          },
        }}
      >
        <Text variant="subtitle1">
          Looks like you&apos;ve wandered into the void. There&apos;s nothing
          here to see.
        </Text>
        <Text variant="body1" sx={{ mt: 3 }}>
          You may have mistyped the address or the page may have moved.
        </Text>
      </Stack>
    </Stack>
  );
}
