import { COLORS, LoadingSkeleton, Tabs } from "@asayinc/component-library";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { EventHeader } from "../Informational/components/EventHeader";
import { ShareholderResources } from "./components/ShareholderResources";
import { GradientBlock } from "src/components/atoms/GradientBlock";
import { BrokerCommunicationPageParams } from "src/types/BrokerCommunicationPageParams";
import { useCommunicationWithTrackingQuery } from "src/store/warrenG/communication";
import { useBrokerQuery } from "src/store/warrenG/broker";

export function InformationalDetails() {
  const theme = useTheme();
  const { brokerId, communicationId } =
    useParams() as BrokerCommunicationPageParams;
  const { isLoading: isCommunicationLoading, data: communication } =
    useCommunicationWithTrackingQuery({
      brokerId,
      communicationId,
    });
  const { isLoading: isBrokerLoading } = useBrokerQuery(brokerId);
  const [activeTab, setTab] = useState<"documents">("documents");
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const isLoading = isCommunicationLoading || isBrokerLoading;

  const handleTabClick = (tab: string) => {
    setTab(tab as "documents");
  };

  if (!isLoading && !communication) {
    return <Box>Error</Box>;
  }

  return (
    <>
      <Stack
        sx={{
          width: "100%",
          backgroundColor: "#FFFFFF",
          display: "none",
          [theme.breakpoints.down("sm")]: {
            display: "flex",
            position: "sticky",
            top: "48px",
            zIndex: "500",
            borderBottom: `6px solid ${
              isLoading ? COLORS.P_OUTLINE : theme.palette.l1.main
            }`,
          },
        }}
      >
        {isLoading ? (
          <Box sx={{ display: "flex", mb: 3, mt: 3 }}>
            <LoadingSkeleton
              sx={{
                display: "table",
                margin: "0 auto",
                width: "80px",
              }}
            />
            <LoadingSkeleton
              sx={{
                display: "table",
                margin: "0 auto",
                width: "80px",
              }}
            />
          </Box>
        ) : (
          <Tabs
            variant="fullWidth"
            TabIndicatorProps={{
              style: {
                backgroundColor: theme.palette.l4.main,
              },
            }}
            tabs={[
              {
                label: "Documents",
                value: "documents",
              },
            ]}
            sx={{
              width: "100%",
              "& .MuiTab-root.Mui-selected": {
                color: theme.palette.l4.main,
              },
            }}
            activeTab={activeTab}
            clickHandler={handleTabClick}
          />
        )}
      </Stack>
      <Stack alignItems="center" mt={12} height="100%">
        <EventHeader sx={{ py: 8, px: 5 }} />
        <Stack
          direction="row"
          sx={{
            pt: 8,
            px: 10,
            mx: "auto",
            width: "100%",
            justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
              pt: 4,
              px: 4,
            },
          }}
        >
          <Box
            maxWidth="600px"
            minWidth="240px"
            width="100%"
            sx={{
              [theme.breakpoints.down("sm")]: {
                display:
                  isSmall && activeTab === "documents" ? "block" : "none",
                ml: 0,
              },
            }}
          >
            <Box
              sx={{
                top: "80px",
                mb: 8,
              }}
            >
              <ShareholderResources
                communication={communication}
                isLoading={isLoading}
                isProxyCommunication={false}
              />
              <Stack
                sx={{
                  display: "none",
                  [theme.breakpoints.down("sm")]: {
                    display: activeTab === "documents" ? "grid" : "none",
                    position: "sticky",
                    bottom: 0,
                    right: 0,
                  },
                }}
              >
                <GradientBlock height="20px" />
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
